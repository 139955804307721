//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 736px;

//  width
//----------------------------------------------------------------------
$pageWidth: 1200px;
$pageWidth-narrow: 1200-76-76px;
$mainWidth: 880px;
$sideWidth: 264px;


//  colors
//----------------------------------------------------------------------
$txtColor: #333;

$gray-border: #e5e5e5;
$gray-dot: #bfbfbf;
$gray-bg: #f0f0f0;

$blue: #317ecc;
$blue-dark: #001779;
$blue-light: #e2e8f6;

$red: #c11227;
$orange: #df6d00;

//  font
//----------------------------------------------------------------------
@mixin fa($code) {
	content: $code;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
