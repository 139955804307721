@import '_config';
@import '_sprite';

/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 3.
*/

/*
見出し1

説明

Markup:
<h1 class="headLine1 {$modifiers}">見出し1テキストテキストテキスト</h1>

.btn-red - red

Styleguide 3.1.1
============================================================*/
.headLine1 {
	border-bottom: 1px solid $gray-border;
	padding: 0 0 5px 25px;
	margin: 40px 0;
	text-indent: -25px;
	font-size: 1.71rem;
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 15px;
		height: 4px;
		background: $blue;
		margin: 0 10px 0 0;
	}

	&-noBorder {
		border-bottom: none;
		padding-bottom: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine1 {
		font-size: 1.38rem;
		margin: 20px 0;
	}
}

/*
見出し2

headLine2

Markup:
<h2 class="headLine2">見出し2テキストテキストテキスト</h2>

Styleguide 3.1.2
============================================================*/
.headLine2 {
	border-radius: 4px;
		padding: 5px 10px;
		margin: 40px 0 25px;
		font-size: 1.29rem;
		background: $blue-light;
}
@media screen and (max-width: $breakpoint-sp){
	.headLine2 {
		font-size: 1.23rem;
	}
}
/*
フォントサイズ

font

Markup:
<p class="{$modifiers}">フォントサイズ</p>

.font-lexL - とても大きい
.font-l - 大きい
.font-s - 小さい
.font-exS - とても小さい

Styleguide 3.2
============================================================*/
.font-lexL {
	font-size: 1.14rem;
}
.font-l {
	font-size: 1.07rem;
}
.font-s {
	font-size: 0.93rem;
}
.font-exS {
	font-size: 0.86rem;
}
@media screen and (max-width: $breakpoint-sp){
	.font-lexL {
		font-size: 1.15rem;
	}
	.font-l {
		font-size: 1.08rem;
	}
	.font-s {
		font-size: 0.92rem;
	}
	.font-exS {
		font-size: 0.85rem;
	}

}

/*
インプット系の幅

.input-w

Markup:
<input type="text" name="" id="" class="{$modifiers}">

.input-wLong - Long
..nput-wMid - Mid
.input-wShort - Short
.input-wMin - Min

Styleguide 3.3
============================================================*/
.input,
[type].input {
	&-wLong {
		width: 80%;
	}
	&-wMid {
		width: 50%;
	}
	&-wShort {
		width: 30%;
	}
	&-wMin {
		width: 15%;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.input,
	[type].input {
		&-wLong {
			width: 100%;
		}
		&-wMid {
			width: 100%;
		}
		&-wShort {
			width: 60%;
		}
		&-wMin {
			width: 25%;
		}
	}

}

/*
ボタン

btn

Markup:
<ul class="btns">
	<li class="btns_btn"><a href="" class="btn {$modifiers}">ボタン</a></li>
	<li class="btns_btn"><button class="btn {$modifiers}">ボタン</button></li>
</ul><!-- /.btns -->

.btn-gray - gray

Styleguide 3.4
============================================================*/
.btns {
	margin: 30px -10px 0;
	display: flex;
	justify-content: center;
	&_btn {
		margin: 10px 10px 0;
	}
}
.btn {
	display: inline-block;
	width: 100%;
	padding: 10px 15px;
	text-align: center;
	text-decoration: none;
	background: $blue;
	color: #fff;
	font-size: 16px;
	min-width: 300px;
	transition: opacity .4s;
	&:hover {
		opacity: .6;
	}

	&-gray {
		background: $gray-bg;
		color: $txtColor;
	}
}

@media screen and (max-width: $breakpoint-sp){
.btns {
	display: block;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}
	&_btn {
		margin: 10px 0 0;
	}
}
	.btn {
		display: block;
		&:hover {
			opacity: 1;
		}
	}

}