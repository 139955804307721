@import '_config';
@import '_sprite';

/*
グローバル

gNavとかgHeaderとか
大まかなレイアウトもここに

Styleguide 2.
*/

/*
大枠

.wrapper
.contents
.main
.side
.sectionとか

Styleguide 2.1
============================================================*/
.wrapper {
	min-width: $pageWidth;
}
.contents {
	margin: 40px auto 0;
	width: $pageWidth;
	&-narrow {
		width: $pageWidth-narrow;
	}
	&:after {
		content: '';
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
	}
	& > :first-child {
		margin-top: 0;
	}
}
.main {
	float: left;
	width: $mainWidth;
	& > :first-child {
		margin-top: 0;
	}
}
.side {
	float: right;
	width: $sideWidth;
	& > :first-child {
		margin-top: 0;
	}
}
.section {
	margin: 60px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		min-width: 100%;
		padding: 50px 0 0;
	}
	.contents {
		width: auto;
		padding: 0 10px;
		margin: 20px 0 0;
		&-narrow {
			width: auto;
		}
	}
	.main,
	.side {
		float: none;
		width: auto;
	}
	.side {
		margin: 40px 0 0;
	}
	.section {
		margin: 40px 0 0;
	}
}

/*
グローバルヘッダ

gHeader
.gNavは別途

Markup:
<header class="gHeader">
	<div class="gHeader_left">
		<figure class="gHeader_logo"><a href="/"><img src="/assets/images/global/logo.png" alt="友愛ビルサービス"></a></figure>
		<p class="gNavBtn"><a href="javascript: void(0);"></a></p>
	</div>
	<nav class="gNav">
		<div class="gNav_inner">
			<ul class="gNav_list">
				<li class="gNav_item gNav_item-01"><a href="/about/">事業内容</a></li>
				<li class="gNav_item gNav_item-02"><a href="/company/">会社概要</a></li>
				<li class="gNav_item gNav_item-03"><a href="/access/">アクセス</a></li>
			</ul>
			<ul class="gNav_subList">
				<ul class="gNav_subList">
					<li class="gNav_subItem gNav_subItem-01"><a href="/recruit/">採用情報</a></li>
					<li class="gNav_subItem gNav_subItem-02"><a href="/contact/">お問い合わせ</a></li>
				</ul>
			</ul>
			<p class="gNav_close"><a href="javascript: void(0);">閉じる</a></p>
		</div>
	</nav><!-- /.gNav -->
</header><!-- /.gHeader -->

Styleguide 2.2
============================================================*/
.gHeader {
	width: $pageWidth;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&_left {
	}
	&_logo {
		margin: 0;
		& > a {
			display: block;
		}
	}
}
.gNavBtn {
	display: none;
}

@media screen and (max-width: $breakpoint-sp){
	.gHeader {
		width: auto;
		display: block;
		&_left {
			position: fixed;
			background: #fff;
			top: 0;
			left: 0;
			right: 0;
			box-shadow: 0 0 5px #888;
			text-align: center;
			padding: 10px;
			z-index: 1000;
		}
		&_logo {
			& > a {
				display: inline-block;
				height: 30px;
			}
		}
	}
	.gNavBtn {
		display: block;
		margin: 0;
		position: absolute;
		top: 50%;
		right: 10px;
		margin: -18px 0 0;
		& > a {
			display: block;
			background: $blue-dark;
			color: #fff;
			width: 36px;
			height: 36px;
			border-radius: 3px;
			text-align: center;
			line-height: 36px;
			&:before {
				@include fa('\f0c9');
				font-size: 30px;
				vertical-align: middle;
			}
		}
	}
}

/*
グロナビ

gNav

Markup:
<nav class="gNav">
	<div class="gNav_inner">
		<ul class="gNav_list">
			<li class="gNav_item gNav_item-01"><a href="/about/">事業内容</a></li>
			<li class="gNav_item gNav_item-02 gNav_item-active"><a href="/company/">会社概要</a></li>
			<li class="gNav_item gNav_item-03"><a href="/access/">アクセス</a></li>
		</ul>
		<ul class="gNav_subList">
			<li class="gNav_subItem gNav_subItem-01"><a href="/recruit/">採用情報</a></li>
			<li class="gNav_subItem gNav_subItem-02"><a href="/contact/">お問い合わせ</a></li>
		</ul>
		<p class="gNav_close"><a href="javascript: void(0);">閉じる</a></p>
	</div>
</nav><!-- /.gNav -->

Styleguide 2.3
============================================================*/
.gNav {
	&_inner {
	}
	&_list {
		overflow: hidden;
		margin: 0;
		float: left;
	}
	&_item {
		float: left;
		border-left: 1px dotted $gray-dot;
		& > a {
			display: block;
			width: 166px;
			height: 81px;
			text-decoration: none;
			color: $txtColor;
			text-align: center;
			padding: 17px 5px;
			&:before {
				content: '';
				display: block;
				margin: 0 auto 5px;
			}
		}
		&-01 {
			& > a:before {
				@include sprite($building-blue-dark);
			}

		}
		&-02 {
			& > a:before {
				@include sprite($bag-blue-dark);
			}

		}
		&-03 {
			& > a:before {
				@include sprite($map-blue-dark);
			}

		}
		& > a:hover,
		&-active > a {
			background: $gray-bg;
			opacity: 1;
		}
	}
	&_subList {
		float: left;
		margin: 0;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_subItem {
		margin: 1px 0 0;
		& > a {
			display: block;
			text-decoration: none;
			background: $blue-dark;
			color: #fff;
			width: 200px;
			height: 40px;
			line-height: 40px;
			padding: 0 15px;
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 0 0;
			}

		}
		&-01 {
			& > a:before {
				@include sprite($man-white);
			}
		}
		&-02 {
			& > a:before {
				@include sprite($mail-white);
			}

		}
	}
	&_close {
		display: none;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gNav {
		z-index: 1200;
		position: fixed;
		top: 0;
		left: -80%;
		bottom: 0;
		width: 80%;
		transition: left .4s;
		background: #fff;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: left .4s;
		&-isOpen {
			box-shadow: 0 0 5px #888;
			left: 0;
		}
		&_inner {
		}
		&_list {
			float: none;
		}
		&_item {
			border-left: none;
			border-bottom: 1px solid $gray-border;
			float: none;
			& > a {
				width: auto;
				height: auto;
				text-align: left;
				padding: 10px;
				&:before {
					display: inline-block;
					margin: 0 15px 0 0;
					vertical-align: middle;
				}
			}
			&-01,
			&-02,
			&-03 {
				& > a:before {
					width: 26px;
				}
			}
			& > a:hover {
				background: none;
			}
		}
		&_subList {
			float: none;
		}
		&_subItem {
			& > a {
				width: auto;
				height: auto;
				background: none;
				color: $txtColor;
				padding: 10px;
				border-bottom: 1px solid $gray-border;
				line-height: 1.6;
				&:before {
					width: 26px;
					margin: 0 19px 0 4px;
					vertical-align: middle;
				}
			}
			&-01 {
				& > a {
					&:before {
						@include sprite($man-blue-dark);
					}

				}
			}
			&-02 {
				& > a {
					&:before {
						@include sprite($mail-blue-dark);
					}

				}
			}
			&-active > a {
				background: $gray-bg;
				opacity: 1;
			}
		}
		&_close {
			display: block;
			padding: 0 10px;
			& > a {
				display: block;
				text-align: center;
				background: $blue-dark;
				color: #fff;
				padding: 8px;
				border-radius: 3px;
				&:before {
					@include fa('\f00d');
					margin: 0 8px 0 0;
				}
			}
		}
	}
}

/*
下のナビ

bottomNav

Markup:
<nav class="bottomNav">
	<div class="bottomNav_inner">
		<figure class="bottomNav_logo"><img src="/assets/images/global/logo.png" alt="友愛ビルサービス"></figure>
		<div class="bottomNav_columns">
			<div class="bottomNav_col">
				<dl class="bottomNav_list">
					<dt class="bottomNav_cate"><a href="/about/">事業内容</a></dt>
					<dd class="bottomNav_item"><a href="/about/">ビルメンテナンス</a></dd>
					<dd class="bottomNav_item"><a href="/about/">その他管理業務</a></dd>
				</dl>
			</div><!-- /.bottomNav_col -->
			<div class="bottomNav_col">
				<dl class="bottomNav_list">
					<dt class="bottomNav_cate"><a href="/company/">会社概要</a></dt>
					<dd class="bottomNav_item"><a href="/company/">会社概要</a></dd>
					<dd class="bottomNav_item"><a href="/company/">企業理念・トップメッセージ</a></dd>
					<dd class="bottomNav_item"><a href="/company/">加入団体・登録番号</a></dd>
				</dl>
			</div><!-- /.bottomNav_col -->
			<div class="bottomNav_col bottomNav_col-address">
				<p class="bottomNav_address">
					<b>株式会社友愛ビルサービス</b><br>
					〒010-0951<br>
					秋田市山王三丁目1番7号　東カンビル<br>
					TEL018-823-1251（代表）<br>
					FAX018-867-1121
				</p>
				<p class="bottomNav_bnr"><a href="http://blaublitz.jp/" target="_blank"><img src="/assets/images/global/blaublitz.jpg" alt="blaublitz"></a></p>
				<p class="bottomNav_fb"><a href=""><img src="/assets/images/global/facebook.png" alt="facebook"></a></p>
			</div><!-- /.bottomNav_col -->
			<div class="bottomNav_col bottomNav_col-earth">
				<figure class="bottomNav_earth"><img src="/assets/images/global/think_earth.png" alt="think earth"></figure>
			</div><!-- /.bottomNav_col -->
		</div><!-- /.bottomNav_columns -->
	</div><!-- /.bottomNav_inner -->
</nav><!-- /.bottomNav -->

Styleguide 2.4
============================================================*/
.bottomNav {
	background: $gray-bg;
	margin: 90px 0 0;
	&_inner {
		width: $pageWidth;
		margin: 0 auto;
		padding: 32px 0 35px;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_logo {
		text-align: center;
	}
	&_columns {
		display: flex;
		justify-content: space-between;
		margin: 35px 0 0;
		& > :first-child {
			border-left: none;
		}
	}
	&_col {
		border-left: 1px dotted $gray-dot;
		padding: 5px 20px;
		flex-grow: 1;
		&-address {
			flex-grow: 0;
			padding-left: 60px;
		}
		&-earth {
			flex-grow: 0;
			border-left: none;
		}
		& > :first-child {
			margin-top: 0;
		}
	}
	&_list {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_cate {
		margin: 5px 0 0;
		& > a {
			display: inline-block;
			padding-left: 14px;
			text-indent: -14px;
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include sprite($arrow-right-black);
				margin: 0 8px 0 0;
			}
		}

	}
	&_item {
		margin: 5px 0 0 14px;
		& > a {
			color: #8d8d8d;
		}
	}
	&_address {
		& > b {
			font-size: 1.07rem;
		}
	}
	&_bnr {
		text-align: center;
	}
	&_fb {
		& > a {
			display: block;
			text-align: center;
			background: $blue;
			padding: 8px;
		}
	}
	&_earth {
	}
}

@media screen and (max-width: $breakpoint-sp){
	.bottomNav {
		margin: 40px 0 0;
		&_inner {
			width: auto;
			padding: 10px;
		}
		&_logo {
			display: none;
		}
		&_columns {
			display: block;
			margin: 0;
		}
		&_col {
			display: none;
			border: none;
			padding: 0;
			&-address,
			&-earth {
				display: block;
			}
			&-earth {
				margin: 10px 0 0;
			}
		}
		&_list {
		}
		&_cate {
		}
		&_item {
		}
		&_address {
		}
		&_fb {
			margin: 10px 0 0;
		}
		&_earth {
			text-align: center;
			height: 80px;
		}
	}
}

/*
グローバルフッタ

gFooter

Markup:
<footer class="gFooter">
	<div class="gFooter_inner">
		<ul class="gFooter_list">
			<li class="gFooter_item"><a href="/privacy.html">プライバシーポリシー</a></li>
			<li class="gFooter_item"><a href="/links.html">リンク集</a></li>
			<li class="gFooter_item"><a href="/sitemap.html">サイトマップ</a></li>
		</ul>
		<p class="gFooter_copyright">&copy;&nbsp;YUAI Building Service co.,ltd. All rights reserved.</p>
	</div>
</footer><!-- /.gFooter -->
<p class="toTop"><a href="#"><img src="/assets/images/global/totop.png" alt="page top"></a></p>

Styleguide 2.5
============================================================*/
.gFooter {
	&_inner {
		width: $pageWidth;
		margin: 0 auto;
		padding: 20px 0;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_list {
		text-align: center;
		letter-spacing: -.44em;
		font-size: 0.93rem;
		& > :first-child {
			&:before {
				content: none;
			}
		}
	}
	&_item {
		display: inline-block;
		vertical-align: top;
		letter-spacing: normal;
		&:before {
			content: '|';
			display: inline-block;
			margin: 0 1em;
		}
	}
	&_copyright {
		font-family: Arial;
		font-size: 10px;
		text-align: center;
		margin: 15px 0 0;
	}
}
.toTop {
	position: fixed;
	right: 20px;
	bottom: 20px;
}

@media screen and (max-width: $breakpoint-sp){
	.gFooter {
		&_inner {
			width: auto;
			padding: 10px;
		}
		&_list {
			display: none;
		}
		&_item {
		}
		&_copyright {
			margin: 0;
		}
	}
}

/*
オーバーレイ

overlay

Markup:
<a href="javascript: void(0);" class="overlay"></a>

.btn-red - red

Styleguide 2.6
============================================================*/
.overlay {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	visibility: hidden;
	background: #fff;
	opacity: 0;
	transition: opacity .4s;
	z-index: 1000;
	&-isOpen {
		display: block;
		visibility: visible;
		opacity: .8;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.overlay {}
}