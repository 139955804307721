@import '_config';
@import '_sprite';

/*
モジュール

その他モジュール

Styleguide 4.
*/

/*
ページタイトル

pageTitle

Markup:
<div class="pageTitle">
	<div class="pageTitle_inner">
		<h1 class="pageTitle_title">
			会社概要
			<span class="pageTitle_en">YUAI Building Service</span>
		</h1>
	</div>
</div><!-- /.pageTitle -->

Styleguide 4.1
============================================================*/
.pageTitle {
		background: url(../images/module/page_title/page_title02.jpg) center bottom no-repeat;
		background-size: cover;
	&_inner {
		width: $pageWidth;
		height: 204px;
		margin: 0 auto;
		letter-spacing: -.44em;
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
			height: 100%;
		}
	}
	&_title {
		display: inline-block;
		vertical-align: middle;
		letter-spacing: normal;
		color: $txtColor;
		font-size: 36px;
		font-weight: normal;
	}
	&_en {
		display: block;
		font-size: 12px;
		font-family: Arial;
		opacity: .4;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageTitle {
		&_inner {
			width: auto;
			height: 80px;
			padding: 0 10px;
		}
		&_title {
			font-size: 1.54rem;
		}
		&_en {
			font-size: 0.92rem;
		}
	}
}

/*
パンクズ

pankuzu

Markup:
<nav class="pankuzu">
	<ul class="pankuzu_list">
		<li class="pankuzu_item"><a href="/">ホーム</a></li>
		<li class="pankuzu_item">会社概要</li>
	</ul>
</nav><!-- /.pankuzu -->

Styleguide 4.2
============================================================*/
.pankuzu {
	width: $pageWidth;
	margin: 15px auto 0;
	&_list {
		margin: 0;
		overflow: hidden;
		& > :first-child {
			&:before {
				content: none;
			}
		}
	}
	&_item {
		float: left;
		& > a {
			color: $blue;
		}
		&:before {
			content: '>';
			padding: 0 .5em;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pankuzu {
		width: auto;
		display: none;
		&_list {
		}
		&_item {
		}
	}
}

/*
スマホでブロックになるテーブル

blockTable

Markup:
<table class="blockTable">
	<tbody>
		<tr>
			<th>商号</th>
			<td>株式会社友愛ビルサービス</td>
		</tr>
		<tr>
			<th>所在地</th>
			<td>〒010-0951<br>秋田県秋田市山王三丁目１番７号　東カンビル</td>
		</tr>
		<tr>
			<th>代表取締役</th>
			<td>小畑　悟</td>
		</tr>
	</tbody>
</table><!-- /.blockTable -->

Styleguide 4.3
============================================================*/

@media screen and (max-width: $breakpoint-sp){
	.blockTable {
		display: block;
		tbody,
		tr,
		th,
		td {
			display: block;
			width: auto;
			border: none;
			padding: 0;
		}
		tbody {
			& > :first-child {
				margin-top: 0;
			}
		}
		tr {
			margin: 20px 0 0;
			& > :first-child {
				margin-top: 0;
			}
		}
		th {
			padding: 5px;
			margin: 20px 0 0;
		}
		td {
			margin: 5px 5px 0;
		}
	}
}

/*
ページリスト

pageList

Markup:
<div class="pageList">
	<div class="pageList_inner">
		<ul class="pageList_list">
			<li class="pageList_item">
				<a href="">
					<p class="pageList_date">2016年10月30日</p>
					<p class="pageList_cate"><span class="pageList_label pageList_label-new">お知らせ</span></p>
					<p class="pageList_title">Webサイトをリニューアルしました。</p>
				</a>
			</li>
			<li class="pageList_item">
				<a href="">
					<p class="pageList_date">2016年10月30日</p>
					<p class="pageList_cate"><span class="pageList_label pageList_label-recruit">採用情報</span></p>
					<p class="pageList_title">2017年度の新規採用情報を更新しました。</p>
				</a>
			</li>
			<li class="pageList_item">
				<a href="">
					<p class="pageList_date">2016年10月30日</p>
					<p class="pageList_cate"><span class="pageList_label pageList_label-event">イベント</span></p>
					<p class="pageList_title">鹿角きりたんぽＦＭさんに『湯夢湯夢の湯』をご紹介いただきました。</p>
				</a>
			</li>
		</ul>
		<p class="pageList_toList"><a href="/news/">ニュース一覧へ</a></p>
	</div>
</div><!-- /.pageList -->

Styleguide 4.4
============================================================*/
.pageList {
	&_inner {
	}
	&_list {
		border-top: 1px solid $gray-border;
	}
	&_item {
		border-bottom: 1px solid $gray-border;
		& > a,
		& > span {
			// display: flex;
			display: block;
			overflow: hidden;
			padding: 15px 40px 15px 0;
			position: relative;
			&:before {
				content: '';
				display: block;
				vertical-align: middle;
				@include sprite($big-arrow-right-gray);
				position: absolute;
				top: 50%;
				right: 21px;
				margin-top: $big-arrow-right-gray-height / -2;
			}

		}
	}
	&_date,
	&_cate,
	&_title {
		padding-left: 20px;
		margin: 0;
		// flex-shrink: 0;
	}

	&_date {
		float: left;
		width: 10em;
	}
	&_cate {
		float: left;
		width: 126px;
	}
	&_label {
		display: block;
		text-align: center;
		font-size: 0.93rem;
		padding: 1px;
		&-new {
			background: $blue;
			color: #fff;
		}
		&-recruit {
			border: 1px solid $blue;
			color: $blue;
		}
		&-event {
			background: $blue-light;
			color: $blue;
		}
	}
	&_title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&_toList {
		text-align: right;
		& > a {
			padding-left: 21px;
			text-indent: -21px;
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include sprite($box-arrow-right-blue);
				margin: 0 8px 0 0;
			}

		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageList {
		&_inner {
		}
		&_list {
		}
		&_item {
			& > a,
			& > span {
				display: block;
				overflow: hidden;
				padding: 10px 18px 10px 5px;
				&:before {
					right: 5px;
				}
				& > :first-child {
					margin-left: 0;
				}
			}
		}
		&_date {
			float: left;
			padding: 0;
			width: auto;
		}
		&_cate {
			float: left;
			padding: 0;
			margin: 0 0 0 10px;
		}
		&_label {
		}
		&_title {
			clear: both;
			padding: 8px 0 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		&_toList {
		}
	}
}

/*
フォーム

.form

Markup:
<form action="" class="form"></form><!-- /.form -->

Styleguide 4.5
============================================================*/
.form {
	margin: 60px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.form {
		margin: 40px 0 0;
	}
}

/*
フォームテーブル

formTable

Markup:
<table class="formTable">
	<tbody>
		<tr>
			<th class="formTable_must">お名前</th>
			<td>
				<div class="formTable_elem"><input type="text" name="" id="" class="input-wMid"></div>
				<p class="formTable_elem formTable_elem-error">エラーエラーエラーエラーエラーエラーエラーエラー</p>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">フリガナ</th>
			<td>
				<div class="formTable_elem"><input type="text" name="" id="" class="input-wMid"></div>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">郵便番号</th>
			<td>
				<div class="formTable_elem"><input type="text" name="" id="" class="input-wMin"> - <input type="text" name="" id="" class="input-wMin"></div>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">都道府県</th>
			<td>
				<div class="formTable_elem" class="input-wShort">
					<select name="" id="">
						<option value="">都道府県</option>
						<option value="">北海道</option>
						<option value="">青森県</option>
						<option value="">岩手県</option>
						<option value="">宮城県</option>
						<option value="">秋田県</option>
						<option value="">山形県</option>
						<option value="">福島県</option>
						<option value="">茨城県</option>
						<option value="">栃木県</option>
						<option value="">群馬県</option>
						<option value="">埼玉県</option>
						<option value="">千葉県</option>
						<option value="">東京都</option>
						<option value="">神奈川県</option>
						<option value="">新潟県</option>
						<option value="">富山県</option>
						<option value="">石川県</option>
						<option value="">福井県</option>
						<option value="">山梨県</option>
						<option value="">長野県</option>
						<option value="">岐阜県</option>
						<option value="">静岡県</option>
						<option value="">愛知県</option>
						<option value="">三重県</option>
						<option value="">滋賀県</option>
						<option value="">京都府</option>
						<option value="">大阪府</option>
						<option value="">兵庫県</option>
						<option value="">奈良県</option>
						<option value="">和歌山県</option>
						<option value="">鳥取県</option>
						<option value="">島根県</option>
						<option value="">岡山県</option>
						<option value="">広島県</option>
						<option value="">山口県</option>
						<option value="">徳島県</option>
						<option value="">香川県</option>
						<option value="">愛媛県</option>
						<option value="">高知県</option>
						<option value="">福岡県</option>
						<option value="">佐賀県</option>
						<option value="">長崎県</option>
						<option value="">熊本県</option>
						<option value="">大分県</option>
						<option value="">宮崎県</option>
						<option value="">鹿児島県</option>
						<option value="">沖縄県</option>
					</select>
				</div>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">市区町村</th>
			<td>
				<div class="formTable_elem"><input type="text" name="" id="" class="input-wLong"></div>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">以降の住所</th>
			<td>
				<div class="formTable_elem"><input type="text" name="" id="" class="input-wLong"></div>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">ラジオ</th>
			<td>
				<ul class="formTable_list">
					<li class="formTable_listItem"><label><input type="radio" name="" id="">ラジオ1</label></li>
					<li class="formTable_listItem"><label><input type="radio" name="" id="">ラジオ2</label></li>
					<li class="formTable_listItem"><label><input type="radio" name="" id="">ラジオ3</label></li>
					<li class="formTable_listItem"><label><input type="radio" name="" id="">ラジオ4</label></li>
					<li class="formTable_listItem"><label><input type="radio" name="" id="">ラジオ5</label></li>
				</ul>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">チェック</th>
			<td>
				<ul class="formTable_list">
					<li class="formTable_listItem"><label><input type="checkbox" name="" id="">チェックボックス1</label></li>
					<li class="formTable_listItem"><label><input type="checkbox" name="" id="">チェックボックス2</label></li>
					<li class="formTable_listItem"><label><input type="checkbox" name="" id="">チェックボックス3</label></li>
					<li class="formTable_listItem"><label><input type="checkbox" name="" id="">チェックボックス4</label></li>
					<li class="formTable_listItem"><label><input type="checkbox" name="" id="">チェックボックス5</label></li>
				</ul>
			</td>
		</tr>
		<tr>
			<th class="formTable_must">お問い合わせ内容</th>
			<td>
				<div class="formTable_elem"><textarea name="" id="" class="input-wLong"></textarea></div>
			</td>
		</tr>
	</tbody>
</table><!-- /.form -->

Styleguide 4.6
============================================================*/
.formTable {
	th,
	td {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_must {
		position: relative;
		padding-right: 53px;
		&:after {
			content: '必須';
			color: #fff;
			font-size: 11px;
			background: #b00;
			padding: 0 8px;
			display: block;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
		}
	}
	&_elem {
		margin: 5px 0 0;
		&-error {
			color: #b00;
		}
	}
	&_list {
	}
	&_listItem {
	}
}

@media screen and (max-width: $breakpoint-sp){
	.formTable {
		tbody,
		tr,
		th,
		td {
			display: block;
			padding: 0;
			border: none;
		}
		tbody {
			& > :first-child {
				margin-top: 0;
			}
		}
		tr {
			margin: 20px 0;
		}
		th {
			padding: 5px;
		}
		td {
			margin: 10px 0 0;
		}
		&_must {
		}
		&_elem {
			&-error {
			}
		}
		&_list {
		}
		&_listItem {
		}
	}

}