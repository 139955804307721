@import '_config';
@import '_sprite';

/*
サイド

その他サイド

Styleguide 5.
*/

/*
サイドナビ

sideNav

Markup:
<div class="sideNav">
	<div class="sideNav_inner">
		<div class="sideNav_title"></div>
		<ul class="sideNav_list">
			<li class="sideNav_item sideNav_item-active"><a href="/about/">会社概要</a></li>
			<li class="sideNav_item"><a href="/about/message.html">企業理念・トップメッセージ</a></li>
			<li class="sideNav_item"><a href="/about/join.html">加入団体・登録番号</a></li>
		</ul>
	</div>
</div><!-- /.sideNav -->

Styleguide 5.1
============================================================*/
.sideNav {
	&_inner {
		border: 1px solid $gray-border;
		border-top: none;
	}
	&_title {
		margin: 0 -1px;
		text-align: center;
		background: $blue-light;
		font-size: 1.21rem;
		color: $blue;
		padding: 8px;
	}
	&_list {
		padding: 0 15px;
		margin: 0;
		& > :first-child {
			border-top: none;
		}
	}
	&_item {
		border-top: 1px dotted $gray-dot;
		& > a {
			display: block;
			padding: 10px 4px 10px 25px;
			text-indent: -21px;
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include sprite($box-arrow-right-blue);
				margin: 0 8px 0 0;
			}
		}
		&-active {
			opacity: .6;
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.sideNav {
		&_inner {
		}
		&_title {
		}
		&_list {
		}
		&_item {
		}
	}
}