@import '_config';
@import '_sprite';

/*============================================================
  pageID-top
============================================================*/
.pageID-top {
	.mainImage {
		min-width: $pageWidth;
		margin: 0 auto;
		overflow: hidden;
		&_inner {
			position: relative;
			top: 0;
			left: 50%;
			margin: 0 0 0 -950px;
		}
	}
	.contents {
		margin-top: 60px;
	}
	.menu {
		margin: 0;
		&_inner {
			display: flex;
			justify-content: space-around;
			& > :first-child {
				// border-left: none;
			}
		}
		&_col {
			display: block;
			flex-basis: 50%;
			border-left: 1px solid #fff;
			background: $blue;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_img {
		}
		&_body {
			padding: 14px 30px;
			color: #fff;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_cate {
			font-size: 1.43rem;
			font-weight: normal;
		}
		&_list {
			margin: 5px -5px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			&-col3 {
				.menu_item {
					width: calc(33% - 10px);
				}
			}
			&-col2 {
				.menu_item {
					width: calc(50% - 10px);
				}
			}
		}
		&_item {
			// flex-basis: 33.33333%;
			padding: 5px 5px 0;
			font-size: 0.86rem;
			opacity: .55;
			text-indent: -1em;
			padding-left: 1em;
			& > a {
				color: #fff;
			}
		}
	}
	.column {
		overflow: hidden;
		&_col {
			&-left {
				float: left;
				width: 830px;
				.headLine1 {
					margin-bottom: 0;
				}
			}
			&-right {
				float: right;
				width: 320px;
				text-align: center;
			}
			& > :first-child {
				margin-top: 0;
			}
		}
	}

}
@media screen and (max-width: $breakpoint-sp){
	.pageID-top {
		.mainImage {
			min-width: 0;
			&_inner {
				margin: 0;
				left: 0;
				height: 205px;
				overflow: hidden;
				width: 100%;
				& > img {
					width: 736px;
					height: 205px;
					max-height: none;
					max-width: none;
					position: absolute;
					top: 0;
					left: 50%;
					margin: 0 0 0 -368px;
				}
			}
		}
		.contents {
			margin: 20px 0 0;
		}
		.menu {
			margin: 0 -10px;
			&_inner {
				display: block;
				& > :first-child {
					margin-top: 0;
				}
			}
			&_col {
				margin: 20px 0 0;
			}
			&_img {
				img {
					width: 100%;
				}
			}
			&_body {
				padding: 14px 15px;
			}
			&_cate {
			}
			&_list {
				&-col3,
				&-col2 {
					.menu_item {
						width: auto;
					}
				}
			}
			&_item {
				font-size: 0.92rem;
			}
		}
		.column {
			& > :first-child {
				margin-top: 0;
			}
			&_col {
				margin: 30px 0 0;
				&-left,
				&-right {
					float: none;
					width: auto;
				}
			}
		}


	}
}

/*============================================================
  pageID-access
============================================================*/
.pageID-access {
	.gMap {
		width: 100%;
		height: 500px;
		margin: 30px 0 0;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.pageID-access {
		.gMap {
			width: 100%;
			height: 150px;
			margin: 20px 0 0;
		}
	}
}

/*============================================================
  pageID-links
============================================================*/
.pageID-links {
	.links {
		&_list {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: -20px 0 0 -40px;
		}
		&_item {
			width: calc(50% - 40px);
			margin: 20px 0 0 40px;
			border-bottom: 1px dotted $gray-dot;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 0 5px;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageID-links {
		.links {
			&_list {
				margin: 0;
				display: block;
				& > :first-child {
					margin-top: 0;
				}
			}
			&_item {
				width: auto;
				display: block;
				margin: 20px 0 0;
				dt {
					text-align: left;
				}
				dd {
					text-align: right;
				}
			}
		}
	}
}

/*============================================================
  pageID-sitemap
============================================================*/
.pageID-sitemap {
	.sitemap {
		&_list {
			overflow: hidden;
			margin: 0;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 1px;
				height: 17px;
				background: #fff;
				position: absolute;
				bottom: 0;
				left: 15px;
				z-index: 100;
			}
			& > :first-child {
				padding-top: 0;
				&:before {
					top: 17px;
				}
				& > a {
					&:before {
						left: -30px;
						width: 30px;
					}
				}
			}
		}
		&_item {
			overflow: hidden;
			position: relative;
			padding: 10px 0 0;
			&:before {
				content: '';
				display: block;
				height: 100%;
				width: 1px;
				background: $gray-border;
				position: absolute;
				top: 0;
				left: 15px;
			}
			& > a {
				display: block;
				width: 230px;
				float: left;
				margin: 0 0 0 30px;
				border-radius: 4px;
				padding: 5px 20px;
				position: relative;
				&:before {
					content: '';
					display: block;
					height: 1px;
					width: 15px;
					background: $gray-border;
					position: absolute;
					top: 50%;
					left: -15px;
				}
			}
			&-top {
				&:before {
					content: none;
				}
				& > a {
					margin-left: 0;
					&:before {
						content: none;
					}
				}
			}
		}
		//リンクカラー
		&_item {
			a {
				background: $blue;
				color: #fff;
				border: none;
			}
			.sitemap_item {
				a {
					background: $blue-light;
					color: $blue;
				}
				.sitemap_item {
					a {
						background: #fff;
						color: $txtColor;
						border: 1px solid $gray-border;
					}
				}

			}
		}

	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageID-sitemap {
		.sitemap {
			& > :first-child {
				margin-left: 0;
			}
			&_list {
				margin: 0 0 0 30px;
				& > :first-child {
					padding-top: 10px;
					&:before {
						top: 0;
					}
					& > a:before {
						left: -15px;
						width: 15px;
					}
				}
			}
			&_item {
				& > a {
					float: none;
					width: auto;
				}
			}
			&_item-top > .sitemap_list {
				margin-left: 0;
			}
		}
	}
}