/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$arrow-down-black-name: 'arrow_down-black';
$arrow-down-black-x: 19px;
$arrow-down-black-y: 20px;
$arrow-down-black-offset-x: -19px;
$arrow-down-black-offset-y: -20px;
$arrow-down-black-width: 7px;
$arrow-down-black-height: 6px;
$arrow-down-black-total-width: 268px;
$arrow-down-black-total-height: 266px;
$arrow-down-black-image: '../images/sprite.png';
$arrow-down-black: (19px, 20px, -19px, -20px, 7px, 6px, 268px, 266px, '../images/sprite.png', 'arrow_down-black', );
$arrow-left-black-name: 'arrow_left-black';
$arrow-left-black-x: 6px;
$arrow-left-black-y: 7px;
$arrow-left-black-offset-x: -6px;
$arrow-left-black-offset-y: -7px;
$arrow-left-black-width: 6px;
$arrow-left-black-height: 7px;
$arrow-left-black-total-width: 268px;
$arrow-left-black-total-height: 266px;
$arrow-left-black-image: '../images/sprite.png';
$arrow-left-black: (6px, 7px, -6px, -7px, 6px, 7px, 268px, 266px, '../images/sprite.png', 'arrow_left-black', );
$arrow-right-black-name: 'arrow_right-black';
$arrow-right-black-x: 0px;
$arrow-right-black-y: 0px;
$arrow-right-black-offset-x: 0px;
$arrow-right-black-offset-y: 0px;
$arrow-right-black-width: 6px;
$arrow-right-black-height: 7px;
$arrow-right-black-total-width: 268px;
$arrow-right-black-total-height: 266px;
$arrow-right-black-image: '../images/sprite.png';
$arrow-right-black: (0px, 0px, 0px, 0px, 6px, 7px, 268px, 266px, '../images/sprite.png', 'arrow_right-black', );
$arrow-up-black-name: 'arrow_up-black';
$arrow-up-black-x: 12px;
$arrow-up-black-y: 14px;
$arrow-up-black-offset-x: -12px;
$arrow-up-black-offset-y: -14px;
$arrow-up-black-width: 7px;
$arrow-up-black-height: 6px;
$arrow-up-black-total-width: 268px;
$arrow-up-black-total-height: 266px;
$arrow-up-black-image: '../images/sprite.png';
$arrow-up-black: (12px, 14px, -12px, -14px, 7px, 6px, 268px, 266px, '../images/sprite.png', 'arrow_up-black', );
$bag-blue-dark-name: 'bag-blueDark';
$bag-blue-dark-x: 216px;
$bag-blue-dark-y: 218px;
$bag-blue-dark-offset-x: -216px;
$bag-blue-dark-offset-y: -218px;
$bag-blue-dark-width: 26px;
$bag-blue-dark-height: 23px;
$bag-blue-dark-total-width: 268px;
$bag-blue-dark-total-height: 266px;
$bag-blue-dark-image: '../images/sprite.png';
$bag-blue-dark: (216px, 218px, -216px, -218px, 26px, 23px, 268px, 266px, '../images/sprite.png', 'bag-blueDark', );
$big-arrow-down-gray-name: 'bigArrow_down-gray';
$big-arrow-down-gray-x: 26px;
$big-arrow-down-gray-y: 26px;
$big-arrow-down-gray-offset-x: -26px;
$big-arrow-down-gray-offset-y: -26px;
$big-arrow-down-gray-width: 14px;
$big-arrow-down-gray-height: 8px;
$big-arrow-down-gray-total-width: 268px;
$big-arrow-down-gray-total-height: 266px;
$big-arrow-down-gray-image: '../images/sprite.png';
$big-arrow-down-gray: (26px, 26px, -26px, -26px, 14px, 8px, 268px, 266px, '../images/sprite.png', 'bigArrow_down-gray', );
$big-arrow-left-gray-name: 'bigArrow_left-gray';
$big-arrow-left-gray-x: 48px;
$big-arrow-left-gray-y: 48px;
$big-arrow-left-gray-offset-x: -48px;
$big-arrow-left-gray-offset-y: -48px;
$big-arrow-left-gray-width: 8px;
$big-arrow-left-gray-height: 14px;
$big-arrow-left-gray-total-width: 268px;
$big-arrow-left-gray-total-height: 266px;
$big-arrow-left-gray-image: '../images/sprite.png';
$big-arrow-left-gray: (48px, 48px, -48px, -48px, 8px, 14px, 268px, 266px, '../images/sprite.png', 'bigArrow_left-gray', );
$big-arrow-right-gray-name: 'bigArrow_right-gray';
$big-arrow-right-gray-x: 40px;
$big-arrow-right-gray-y: 34px;
$big-arrow-right-gray-offset-x: -40px;
$big-arrow-right-gray-offset-y: -34px;
$big-arrow-right-gray-width: 8px;
$big-arrow-right-gray-height: 14px;
$big-arrow-right-gray-total-width: 268px;
$big-arrow-right-gray-total-height: 266px;
$big-arrow-right-gray-image: '../images/sprite.png';
$big-arrow-right-gray: (40px, 34px, -40px, -34px, 8px, 14px, 268px, 266px, '../images/sprite.png', 'bigArrow_right-gray', );
$big-arrow-up-gray-name: 'bigArrow_up-gray';
$big-arrow-up-gray-x: 56px;
$big-arrow-up-gray-y: 62px;
$big-arrow-up-gray-offset-x: -56px;
$big-arrow-up-gray-offset-y: -62px;
$big-arrow-up-gray-width: 14px;
$big-arrow-up-gray-height: 8px;
$big-arrow-up-gray-total-width: 268px;
$big-arrow-up-gray-total-height: 266px;
$big-arrow-up-gray-image: '../images/sprite.png';
$big-arrow-up-gray: (56px, 62px, -56px, -62px, 14px, 8px, 268px, 266px, '../images/sprite.png', 'bigArrow_up-gray', );
$box-arrow-down-blue-name: 'boxArrow_down-blue';
$box-arrow-down-blue-x: 83px;
$box-arrow-down-blue-y: 83px;
$box-arrow-down-blue-offset-x: -83px;
$box-arrow-down-blue-offset-y: -83px;
$box-arrow-down-blue-width: 13px;
$box-arrow-down-blue-height: 13px;
$box-arrow-down-blue-total-width: 268px;
$box-arrow-down-blue-total-height: 266px;
$box-arrow-down-blue-image: '../images/sprite.png';
$box-arrow-down-blue: (83px, 83px, -83px, -83px, 13px, 13px, 268px, 266px, '../images/sprite.png', 'boxArrow_down-blue', );
$box-arrow-left-blue-name: 'boxArrow_left-blue';
$box-arrow-left-blue-x: 70px;
$box-arrow-left-blue-y: 70px;
$box-arrow-left-blue-offset-x: -70px;
$box-arrow-left-blue-offset-y: -70px;
$box-arrow-left-blue-width: 13px;
$box-arrow-left-blue-height: 13px;
$box-arrow-left-blue-total-width: 268px;
$box-arrow-left-blue-total-height: 266px;
$box-arrow-left-blue-image: '../images/sprite.png';
$box-arrow-left-blue: (70px, 70px, -70px, -70px, 13px, 13px, 268px, 266px, '../images/sprite.png', 'boxArrow_left-blue', );
$box-arrow-right-blue-name: 'boxArrow_right-blue';
$box-arrow-right-blue-x: 96px;
$box-arrow-right-blue-y: 96px;
$box-arrow-right-blue-offset-x: -96px;
$box-arrow-right-blue-offset-y: -96px;
$box-arrow-right-blue-width: 13px;
$box-arrow-right-blue-height: 13px;
$box-arrow-right-blue-total-width: 268px;
$box-arrow-right-blue-total-height: 266px;
$box-arrow-right-blue-image: '../images/sprite.png';
$box-arrow-right-blue: (96px, 96px, -96px, -96px, 13px, 13px, 268px, 266px, '../images/sprite.png', 'boxArrow_right-blue', );
$box-arrow-up-blue-name: 'boxArrow_up-blue';
$box-arrow-up-blue-x: 109px;
$box-arrow-up-blue-y: 109px;
$box-arrow-up-blue-offset-x: -109px;
$box-arrow-up-blue-offset-y: -109px;
$box-arrow-up-blue-width: 13px;
$box-arrow-up-blue-height: 13px;
$box-arrow-up-blue-total-width: 268px;
$box-arrow-up-blue-total-height: 266px;
$box-arrow-up-blue-image: '../images/sprite.png';
$box-arrow-up-blue: (109px, 109px, -109px, -109px, 13px, 13px, 268px, 266px, '../images/sprite.png', 'boxArrow_up-blue', );
$building-blue-dark-name: 'building-blueDark';
$building-blue-dark-x: 194px;
$building-blue-dark-y: 194px;
$building-blue-dark-offset-x: -194px;
$building-blue-dark-offset-y: -194px;
$building-blue-dark-width: 22px;
$building-blue-dark-height: 24px;
$building-blue-dark-total-width: 268px;
$building-blue-dark-total-height: 266px;
$building-blue-dark-image: '../images/sprite.png';
$building-blue-dark: (194px, 194px, -194px, -194px, 22px, 24px, 268px, 266px, '../images/sprite.png', 'building-blueDark', );
$mail-blue-dark-name: 'mail-blueDark';
$mail-blue-dark-x: 140px;
$mail-blue-dark-y: 136px;
$mail-blue-dark-offset-x: -140px;
$mail-blue-dark-offset-y: -136px;
$mail-blue-dark-width: 18px;
$mail-blue-dark-height: 14px;
$mail-blue-dark-total-width: 268px;
$mail-blue-dark-total-height: 266px;
$mail-blue-dark-image: '../images/sprite.png';
$mail-blue-dark: (140px, 136px, -140px, -136px, 18px, 14px, 268px, 266px, '../images/sprite.png', 'mail-blueDark', );
$mail-white-name: 'mail-white';
$mail-white-x: 122px;
$mail-white-y: 122px;
$mail-white-offset-x: -122px;
$mail-white-offset-y: -122px;
$mail-white-width: 18px;
$mail-white-height: 14px;
$mail-white-total-width: 268px;
$mail-white-total-height: 266px;
$mail-white-image: '../images/sprite.png';
$mail-white: (122px, 122px, -122px, -122px, 18px, 14px, 268px, 266px, '../images/sprite.png', 'mail-white', );
$man-blue-dark-name: 'man-blueDark';
$man-blue-dark-x: 176px;
$man-blue-dark-y: 172px;
$man-blue-dark-offset-x: -176px;
$man-blue-dark-offset-y: -172px;
$man-blue-dark-width: 18px;
$man-blue-dark-height: 22px;
$man-blue-dark-total-width: 268px;
$man-blue-dark-total-height: 266px;
$man-blue-dark-image: '../images/sprite.png';
$man-blue-dark: (176px, 172px, -176px, -172px, 18px, 22px, 268px, 266px, '../images/sprite.png', 'man-blueDark', );
$man-white-name: 'man-white';
$man-white-x: 158px;
$man-white-y: 150px;
$man-white-offset-x: -158px;
$man-white-offset-y: -150px;
$man-white-width: 18px;
$man-white-height: 22px;
$man-white-total-width: 268px;
$man-white-total-height: 266px;
$man-white-image: '../images/sprite.png';
$man-white: (158px, 150px, -158px, -150px, 18px, 22px, 268px, 266px, '../images/sprite.png', 'man-white', );
$map-blue-dark-name: 'map-blueDark';
$map-blue-dark-x: 242px;
$map-blue-dark-y: 241px;
$map-blue-dark-offset-x: -242px;
$map-blue-dark-offset-y: -241px;
$map-blue-dark-width: 26px;
$map-blue-dark-height: 25px;
$map-blue-dark-total-width: 268px;
$map-blue-dark-total-height: 266px;
$map-blue-dark-image: '../images/sprite.png';
$map-blue-dark: (242px, 241px, -242px, -241px, 26px, 25px, 268px, 266px, '../images/sprite.png', 'map-blueDark', );
$spritesheet-width: 268px;
$spritesheet-height: 266px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($arrow-down-black, $arrow-left-black, $arrow-right-black, $arrow-up-black, $bag-blue-dark, $big-arrow-down-gray, $big-arrow-left-gray, $big-arrow-right-gray, $big-arrow-up-gray, $box-arrow-down-blue, $box-arrow-left-blue, $box-arrow-right-blue, $box-arrow-up-blue, $building-blue-dark, $mail-blue-dark, $mail-white, $man-blue-dark, $man-white, $map-blue-dark, );
$spritesheet: (268px, 266px, '../images/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
