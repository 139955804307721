@import '_config';
@import '_sprite';

/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/

/* 汎用
-------------------------------------------------------------*/
@for $i from 0 through 100 {
	.mt#{$i} {
		margin-top: #{$i}px !important;
	}
	.mr#{$i} {
		margin-right: #{$i}px !important;
	}
	.mb#{$i} {
		margin-bottom: #{$i}px !important;
	}
	.ml#{$i} {
		margin-left: #{$i}px !important;
	}
	.pt#{$i} {
		padding-top: #{$i}px !important;
	}
	.pr#{$i} {
		padding-right: #{$i}px !important;
	}
	.pb#{$i} {
		padding-bottom: #{$i}px !important;
	}
	.pl#{$i} {
		padding-left: #{$i}px !important;
	}
	.indent#{$i}e {
		text-indent: #{$i*-.1}em !important; padding-left: #{$i*.1}em !important;
	}
	@media screen and (max-width: $breakpoint-sp){
		.mt#{$i}-sp {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-sp {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-sp {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-sp {
			margin-left: #{$i}px !important;
		}
		.pt#{$i}-sp {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-sp {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-sp {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-sp {
			padding-left: #{$i}px !important;
		}
	}
	@media screen and (min-width: $breakpoint-sp +1){
		.mt#{$i}-pc {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-pc {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-pc {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-pc {
			margin-left: #{$i}px !important;
		}
		.pt#{$i}-pc {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-pc {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-pc {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-pc {
			padding-left: #{$i}px !important;
		}
	}
}

.fLeft { float: left !important; }
.fRight { float: right !important; }
.tLeft  { text-align: left !important; }
.tCenter { text-align: center !important; }
.tRight { text-align: right !important; }
.vat { vertical-align: top !important; }
.vam { vertical-align: middle !important; }
.vab { vertical-align: bottom !important; }
.clearfix:before,
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}
.noAround {
	overflow: hidden;
	*zoom: 1;
}
.clear, .clb { clear: both; }

/*
レスポンシブ用　表示/非表示

.(sp/tab/pc)-onlyで.(sp/tab/pc)でのみ表示

.(sp/tab/pc)-hiddenで.(sp/tab/pc)でのみ非表示

つまり.sp-onlyでtab/pcは非表示に、.pc-onlyでtab/spは非表示に、

Markup:

Styleguide 1.1
============================================================*/
@media screen and (max-width: $breakpoint-sp) {
	.sp-hidden,
	.pc-only,
	.tab-only {
		display: none !important;
	}
}

@media screen and (min-width: $breakpoint-sp + 1) {
	.pc-hidden,
	.tab-only,
	.sp-only {
		display: none !important;
	}
}
